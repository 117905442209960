<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'AuthBase',
};
</script>

<style></style>
